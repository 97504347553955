import { useEffect } from "react";

const GoogleTranslate = () => {
    const googleTranslateElementInit = () => {
        if (!window.google || !window.google.translate) return; // Ensure the script has loaded properly
        
        if (!document.getElementById("google_translate_element").innerHTML) {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: "en",
                    autoDisplay: true,
                    includedLanguages: "nl,ja,ko,tl,ar,zh-CN,fr,fa,ru,es,en,th"
                },
                "google_translate_element"
            );
        }
    };

    useEffect(() => {
        // Check if the script is already added
        if (!document.querySelector('script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]')) {
            var addScript = document.createElement("script");
            addScript.setAttribute(
                "src",
                "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
            );
            document.body.appendChild(addScript);
            window.googleTranslateElementInit = googleTranslateElementInit;


        }

        // Clean up script and translate element on component unmount
        return () => {
            const existingScript = document.querySelector('script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]');
            if (existingScript) {
                document.body.removeChild(existingScript);
            }
            const translateElement = document.getElementById("google_translate_element");

            if (translateElement) {
                translateElement.innerHTML = '';
            }
            delete window.googleTranslateElementInit;
        };
    }, []);

    

    return (
        <>
           
            <div id="google_translate_element" className="google-translate-container"></div>
           
            {/* Add the CSS directly in the component */}
            <style jsx="true">{`
        #google_translate_element .skiptranslate div,
        .goog-te-gadget .goog-te-combo {
          display: flex !important;
          font-size: 14px !important;
          color: purple !important;
          font-family: "Poppins", sans-serif !important;
          font-weight:400;
          width: 100% !important;
          padding: 3px 5px !important;
          border-radius: 10px !important;
        }
        .VIpgJd-ZVi9od-l4eHX-hSRGPd {
        display: none !important;
        }
        .VIpgJd-ZVi9od-l4eHX-hSRGPd,  /* The Translate link and logo */
        .goog-te-banner-frame.skiptranslate, /* Any floating banner that might appear */
        .goog-logo-link, /* The "Powered by Google" link */
        .goog-te-gadget > span  { /* Additional spans that might include branding */
        display: none !important;
        }
        .goog-logo-link {
          display: none !important;
        }
        #google_translate_element {
          display: inline-block;
        }
        /* Hiding the Google Translate logo */
        .goog-logo-link {
          display: none !important;
        }
      .goog-te-gadget {
    display: flex !important;
    color: transparent !important;
    flex-direction: row; /* or 'column' */
    justify-content: center; /* Align items horizontally (center, start, end, space-between, etc.) */
    align-items: center; /* Align items vertically (center, start, end, stretch, etc.) */
}

        .skiptranslate > iframe {
          display: none !important;
        }
          @media (max-width: 768px) {
                    #google_translate_element .goog-te-combo {
                        width: 100% !important; /* Full width on smaller screens */
                        margin: 0 auto; /* Center the dropdown */
                        padding: 5px !important;
                        font-size: 12px !important;
                    }
                }
                @media (max-width: 480px) {
                    #google_translate_element .goog-te-combo {
                        font-size: 11px !important;
                        padding: 4px !important;
                    }
                }
      `}</style>

        </>
    );
};

export default GoogleTranslate;

// import { useEffect, useRef } from "react";

// const GoogleTranslate = () => {
//   const translateRef = useRef(null);

//   const googleTranslateElementInit = () => {
//     if (window.google && window.google.translate) {
//       new window.google.translate.TranslateElement(
//         {
//           pageLanguage: "en",
//           autoDisplay: true,
//           includedLanguages: "nl,ja,ko,tl,ar,zh-CN,fr,fa,ru,es,en,th",
//         },
//         translateRef.current
//       );
//     } else {
//       console.error("Google Translate script is not loaded correctly.");
//     }
//   };

//   useEffect(() => {
//     const scriptId = 'google-translate-script';
//     let script = document.getElementById(scriptId);

//     if (!script) {
//       script = document.createElement("script");
//       script.id = scriptId;
//       script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
//       script.async = true;
//       script.onload = () => console.log("Google Translate script loaded.");
//       script.onerror = () => console.error("Failed to load Google Translate script.");

//       window.googleTranslateElementInit = googleTranslateElementInit;
//       document.body.appendChild(script);
//     } else {
//       googleTranslateElementInit();
//     }

//     return () => {
//       const existingScript = document.getElementById(scriptId);
//       if (existingScript) {
//         document.body.removeChild(existingScript);
//       }

//       if (translateRef.current) {
//         translateRef.current.innerHTML = "";
//       }

//       delete window.googleTranslateElementInit;

//       const iframes = document.querySelectorAll('iframe.skiptranslate');
//       iframes.forEach((iframe) => {
//         if (iframe.parentNode) {
//           iframe.parentNode.removeChild(iframe);
//         }
//       });
//     };
//   }, []);

//   return (
//     <div ref={translateRef} className="google-translate-container"></div>
//   );
// };

// export default GoogleTranslate;


