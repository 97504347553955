import React, { useEffect } from 'react';
import bg_image from '../Image/Website_images/BGNEW.webp';
import weare from '../Image/Website_images/we-are.webp';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Aos from 'aos';

function StepSix() {

    const { ref, inView } = useInView({
        triggerOnce: true, 
        threshold: 0.1, 
    });

    // useEffect(() => {
    //     Aos.init({ duration: 2000 });
    // }, []);

    return (
        <>
            <div id="Why" className="relative lg:h-[780px]">
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 50 }}
                animate={inView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.8 }}
                className="hidden lg:block absolute inset-0 bg-cover bg-center rounded-2xl"
                style={{ backgroundImage: `url(${bg_image})` }}
            ></motion.div>
            <div className="relative container mx-auto grid grid-cols-1 lg:grid-cols-2 items-center h-full text-white py-10">
                <div></div>
                <div
                    initial={{ opacity: 0, x: 50 }}
                    animate={inView ? { opacity: 1, x: 0 } : {}}
                    transition={{ duration: 0.8 }}
                //    data-aos="fade-left"
                    className="container text-justify text-black p-0 md:p-8 rounded-lg"
                >
                    <p   className='mb-6 text-sm md:text-base'>
                        Algorithmic trading bots that integrate spot and futures trading functionalities offer a comprehensive range of benefits to traders. These bots streamline trade execution with advanced charting tools, customizable strategies, and automated order placement, ensuring efficient trading operations. They provide access to 24/7 markets, enabling traders to capitalize on global opportunities without time constraints. Robust risk management tools help mitigate potential losses, while real-time market data ensures timely decision-making.
                    </p>
                    <p  className='mb-6 text-sm md:text-base'>
                        This technological approach not only enhances trading efficiency but also empowers traders to maintain competitive advantages in dynamic market conditions. Whether executing complex strategies or managing multiple assets simultaneously, algorithmic trading bots are indispensable tools for modern traders seeking to enhance their trading performance and profitability.
                    </p>

                    <button className='bg-SecondaryColor text-white px-8 py-2 rounded-md font-bold mt-4'>
                        <a href="https://app.odecent.com">Join Now</a>
                    </button>
                </div>
            
        </div>
                {/* <div
                    className="hidden lg:block absolute inset-0 bg-cover bg-center rounded-2xl "
                    style={{ backgroundImage: `url(${bg_image})`, }}
                ></div>
                <div className="relative container mx-auto grid grid-cols-1 lg:grid-cols-2  items-center  h-full text-white py-10">
                    <div></div>
                    <div className="container text-justify text-black p-0 md:p-8 rounded-lg">
                        <p className='mb-6 text-sm md:text-base'>
                            Algorithmic trading bots that integrate spot and futures trading functionalities offer a comprehensive range of benefits to traders. These bots streamline trade execution with advanced charting tools, customizable strategies, and automated order placement, ensuring efficient trading operations. They provide access to 24/7 markets, enabling traders to capitalize on global opportunities without time constraints. Robust risk management tools help mitigate potential losses, while real-time market data ensures timely decision-making.
                        </p><p className='mb-6 text-sm md:text-base'>
                            This technological approach not only enhances trading efficiency but also empowers traders to maintain competitive advantages in dynamic market conditions. Whether executing complex strategies or managing multiple assets simultaneously, algorithmic trading bots are indispensable tools for modern traders seeking to enhance their trading performance and profitability.
                        </p>

                        <button className='bg-SecondaryColor text-white px-8 py-2 rounded-md font-bold mt-4'><a href="https://app.odecent.com">Join Now</a></button>                    </div>
                </div> */}
            </div>

            <div id="Work" className='container mx-auto gap-20 grid grid-cols-1 lg:grid-cols-2 text-justify'>
                <div className='my-4'>
                    <h1 className='text-3xl font-bold mb-4'>How We Work</h1>
                    <strong className='font-medium '>YOU WILL ALSO ENJOY</strong>

                    <p className='text-xs md:text-sm my-2'>Becoming a part of the Odecian Victor in which opens the door to a host of exclusive<br />  benefits designed to elevate your success and leadership. As an Odecian Victor, you <br /> unlock enhanced earning potential through lucrative mission and premium rewards,<br />  establishing yourself as a beacon of achievement.
                    </p>
                    <p className='text-xs md:text-sm my-2'>
                        Key decisions within the team, actively participating in leadership forums and<br />  enjoying global recognition for their outstanding achievements. Joining the Odecian <br /> Victor is not just a step forward; it's a significant leap towards unparalleled success <br /> and distinction </p>
                    <button className='bg-SecondaryColor text-white px-8 py-2 rounded-md font-bold mt-4'><a href="https://app.odecent.com">Join Now</a></button>           
                     </div>
                     <div><img src={weare} alt="weare" /></div>
            </div>
        </>
    );
}

export default StepSix;
