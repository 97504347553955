import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import RunBots from './Component/RunBots';
import Advantage from './Component/Advantage';
import EasyStep from './Component/EasyStep';
import StepSix from './Component/StepSix';
import Journey from './Component/Journey';
import Join from './Component/Join';
import Footer from './Component/Footer';
import CardShow from './Component/CardShow';
import Guide from '../src/Pages/Guide';
import "../src/App.css"

function MainComponents() {
  const location = useLocation();
  const shouldRenderMainComponents = location.pathname !== '/guide';

  return (
    <>
      {shouldRenderMainComponents && (
        <>
        <Home />
          <Advantage />
          
          <RunBots />
          <EasyStep />
          <StepSix />
          <CardShow />
          <Journey />
          <Join />
          <Footer />
        </>
      )}
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
      
        <Route path="/guide" element={<Guide />} />
        
      </Routes>
      <MainComponents />
    </Router>
  );
}

export default App;
