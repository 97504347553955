import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import card1 from "../Image/CARD/BOT50.webp";
import card2 from "../Image/CARD/BOT100.webp";
import card3 from "../Image/CARD/BOT250.webp";
import card4 from "../Image/CARD/BOT500.webp";
import card5 from "../Image/CARD/BOT1000.webp";
import card6 from "../Image/CARD/BOT3000.webp";
import card7 from "../Image/CARD/BOT5000.webp";
import card8 from "../Image/CARD/BOT10000.webp";
import Aos from "aos";
import axios from "axios";

function PackageCarousel() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 3,
    initialSlide: 1,
    beforeChange: (current, next) => setCenterIndex(next),
    // responsive: [
    //   {
    //     breakpoint: 3024,
    //     settings: {
    //       slidesToShow: 3,
    //     },
    //   },
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       centerPadding: "40px",
    //     },
    //   },
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow: 2,
    //       centerPadding: "30px",
    //     },
    //   },
    //   {
    //     breakpoint: 370,
    //     settings: {
    //       slidesToShow: 1,
    //       centerPadding: "15px",
    //     },
    //   },
    //   {
    //     breakpoint: 326,
    //     settings: {
    //       slidesToShow: 1,
    //       centerPadding: "15px",
    //     },
    //   },
    // ],
    responsive: [
      {
        breakpoint: 450, // From 320px to 450px
        settings: {
          slidesToShow: 1,
          centerPadding: "15px",
        },
      },
      {
        breakpoint: 786, // From 451px to 786px
        settings: {
          slidesToShow: 2,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 3024, // From 787px and up
        settings: {
          slidesToShow: 3,
          centerPadding: "40px",
        },
      },
    ],
  };

  const [centerIndex, setCenterIndex] = React.useState(1);
  const [filteredData, setFilteredData] = React.useState([]);

  const packages = [
    { id: 1, name: "BOT50", value: "$50", apis: "1", bots: "4", image: card1 },
    { id: 2, name: "BOT100", value: "$100", apis: "2", bots: "6", image: card2 },
    { id: 3, name: "BOT250", value: "$250", apis: "2", bots: "8", image: card3 },
    { id: 4, name: "BOT500", value: "$500", apis: "3", bots: "10", image: card4 },
    { id: 5, name: "BOT1000", value: "$1000", apis: "4", bots: "12", image: card5 },
    { id: 6, name: "BOT3000", value: "$3000", apis: "5", bots: "15", image: card6 },
    { id: 7, name: "BOT5000", value: "$5000", apis: "6", bots: "20", image: card7 },
    { id: 8, name: "BOT10000", value: "$10000", apis: "7", bots: "25", image: card8 },
  ];

  const mapApiResponseToPackages = (apiData) => {
    return apiData.map((item) => ({
      id: item.ID,
      name: item.Name,
      value: `$${item.Price}`,
      apis: item.Live_API,
      bots: item.Live_Bots, 
      image: item.Dashboard // assuming image URL is in the Dashboard field
    }));
  };
  

  React.useEffect(() => {
    console.log("Current center index:", centerIndex);
  }, [centerIndex]);

  const gettransactions = () => {
    
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: "https://public2.odecents.com/public/subscription",
    })
      .then(async function (response) {
        const res = await response.data.Success;
        const botPackages = res.filter(item => item.Package_Type === "BOT");
        // Map API response to your packages format
        const formattedPackages = mapApiResponseToPackages(botPackages);
        setFilteredData(formattedPackages);
      })
      .catch((error) => {  
        console.log(error);
      });
  };

  useEffect(() => {
    gettransactions();
  }, []);


  return (
    <div className="container mx-auto mt-20">
      <h2 className="text-center text-xl lg:text-3xl font-bold mb-8">
        Explore Our Exclusive <span className="text-primaryColor underline">Packages</span>
      </h2>
      <div className="slider-container">
        <Slider  {...settings}>
          {filteredData.map((pkg, index) => (
            <div className="silder-gap h-[500px] lg:h-[600px]" key={pkg.id}>
              <div
                className={`flex relative flex-col gap-10 text-white items-center rounded-lg ${
                  centerIndex === index ? "bg-image-1 h-[250px] xl:h-[350px]" : "bg-image-2 h-[230px] xl:h-[320px]"
                } transition-transform transform ${
                  centerIndex === index ? "scale-105" : "scale-100"
                }`}
              >
                <div
                  className={`flex absolute z-40 items-center justify-center w-full ${
                    centerIndex === index ? "-top-16 lg:-top-28" : "-top-14"
                  }`}
                >
                  <img
                    src={pkg.image}
                    alt={pkg.name}
                    className={`h-full transition-transform ${
                      centerIndex === index ? "filter-none" : "filter grayscale"
                    }`}
                  />
                </div>
                <div
                  className={`mt-4 w-full absolute font-semibold text-center bg-stone-800 bg-opacity-50 px-7 ${
                    centerIndex === index ? "bottom-16" : "bottom-9"
                  }`}
                >
                  <p className="flex justify-between text-[9px] md:text-xs xl:text-base my-1 items-center">
                    PACKAGE NAME: <span>{pkg.name}</span>
                  </p>
                  <p className="flex justify-between text-[9px] md:text-xs xl:text-base my-1 items-center">
                    PACKAGE VALUE: <span>{pkg.value}</span>
                  </p>
                  <p className="flex justify-between text-[9px] md:text-xs xl:text-base my-1 items-center">
                    LIVE APIs: <span>{pkg.apis}</span>
                  </p>
                  <p className="flex justify-between text-[9px] md:text-xs xl:text-base my-1 items-center">
                    NO OF BOTS ACTIVE: <span>{pkg.bots}</span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default PackageCarousel;
