import React, { useState, useEffect, useRef } from 'react';
import home1 from '../Image/Navbar/Home-slide-1.webp';
import home2 from '../Image/Navbar/Home-slide-2.webp';
import home3 from '../Image/Navbar/Home-slide-3.webp';

const slides = [
  {
    id: 1,
    text: 'EXPLORE ALGO PRECISION',
    strong: "TRADE SMARTER, NOT HARDER",
    p: `Odecent empowers you with algorithmic bots,<br/> accessible to both newcomers and seasoned traders.`,
    bgImage: home1,
  },
  {
    id: 2,
    text: 'INNOVATION AND GROWTH',
    strong: "TRADE SMARTER, NOT HARDER",
    p: `Odecent empowers you with algorithmic bots,<br/> accessible to both newcomers and seasoned traders.`,
    bgImage: home2,
  },
  {
    id: 3,
    text: 'INNOVATION AND GROWTH',
    strong: "TRADE SMARTER, NOT HARDER",
    p: `Odecent empowers you with algorithmic bots,<br/> accessible to both newcomers and seasoned traders.`,
    bgImage: home3,
  },
];

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [progress, setProgress] = useState(0);
  const textRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => prev + 1);
      if (progress >= 100) {
        nextSlide();
        setProgress(0);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [progress]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
    setProgress(0);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
    setProgress(0);
  };

  return (
    <div className="relative w-full h-[500px] lg:h-[900px] overflow-hidden" id='Home'>
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`absolute top-0 left-0 w-full transition-transform duration-1000 transform ${currentSlide === index ? 'opacity-100 zoom-in-animation' : 'opacity-0'
            }`}
          style={{
            backgroundImage: `url(${slide.bgImage})`,
            backgroundSize: 'cover',
            height: '950px',
            backgroundPosition: 'center',
            display: "block",
            backgroundRepeat: 'no-repeat',
            overflow: 'hidden',
          }}
        >
          <div className=" flex items-center justify-center w-full h-[700px] lg:h-full">
            <div className="text-center">
              <div ref={textRef} className='text-white text-center mx-4'>
                <h1 className="text-md lg:text-4xl font-bold text-white">
                  {slide.text}
                </h1>
                <strong className='text-xs md:text-base  my-2'>
                  {slide.strong}
                </strong>
                <p className="text-white text-[9px] md:text-base" dangerouslySetInnerHTML={{ __html: slide.p }}></p>
                <button
                
                  className="text-sm md:text-base relative bg-white my-4 py-1 rounded-lg px-4 border border-SecondaryColor text-SecondaryColor hover:text-white hover:bg-transparent transition-colors"
                >
                  <a href="https://app.odecent.com">Join Now</a>
                  {/* <div className="absolute left-0 bottom-7 w-full h-0.5 bg-white scale-x-0 group-hover:scale-x-100 group-active:scale-x-100 transition-transform"></div> */}
                </button>
              </div>
              <div className="relative mt-2">
                <div className="h-1 bg-gray-300 " style={{ width: textRef.current?.offsetWidth || '100%' }}>
                  <div className="h-full bg-purple-700" style={{ width: `${progress}%` }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="hidden lg:block absolute container mx-auto  top-1/2 left-0 transform -translate-y-1/2 z-20">
        {slides.map((_, index) => (
          <div
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-1 h-9 mb-2 cursor-pointer rounded-full ${currentSlide === index ? 'bg-purple-700' : 'bg-gray-300'
              }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
