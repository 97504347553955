import React from "react";
import { useState, useEffect } from "react";
import guide from "../assets/img/guideImg.webp";
import guidemobile from "../assets/img/mobileimgbanner.webp";
import connectLive2 from "../assets/img/connnectLive/1.webp";
import connectLive3 from "../assets/img/connnectLive/2.webp";
import connectLive4 from "../assets/img/connnectLive/3.webp";
import connectLive5 from "../assets/img/connnectLive/4.webp";
import connectLive6 from "../assets/img/connnectLive/5.webp";
import connectLive7 from "../assets/img/connnectLive/6.webp";
import connectLive8 from "../assets/img/connnectLive/7.webp";
import connectLive10 from "../assets/img/connnectLive/8.webp";
import connectLive11 from "../assets/img/connnectLive/10.webp";
import connectLive12 from "../assets/img/connnectLive/11.webp";
import connectLive13 from "../assets/img/connnectLive/12.webp";
import connectLive14 from "../assets/img/connnectLive/13.webp";
import connectLive15 from "../assets/img/connnectLive/14.webp";
import connectpaper1 from "../assets/img/Paperapi/1.webp";
import connectpaper2 from "../assets/img/Paperapi/2.webp";
import connectpaper4 from "../assets/img/Paperapi/4.webp";
import connectpaper5 from "../assets/img/Paperapi/5.webp";
import bot4 from "../assets/img/Bot/3.webp";
import riskspot from "../assets/img/risk/Spotrisk.webp";
import riskfuture from "../assets/img/risk/futurerisk.webp";
import bot5 from "../assets/img/Bot/4.webp";
import bot6 from "../assets/img/Bot/6.webp";
import b1 from "../assets/img/Bot/1.webp";
import b2 from "../assets/img/Bot/2.webp";
import bot55 from "../assets/img/Bot/5.webp";
import botnew from "../assets/img/Bot/botnew.webp";
import tele1 from "../assets/img/telegram/1.jpg";
import tele2 from "../assets/img/telegram/3.jpg";
import tele3 from "../assets/img/telegram/2.jpg";
import { AiOutlineLogin } from "react-icons/ai";
import { BsGraphUpArrow, BsPersonFillAdd } from "react-icons/bs";
import { AiFillApi } from "react-icons/ai";
import { TbPremiumRights } from "react-icons/tb";
import { RiCheckboxCircleLine, RiRobot2Line } from "react-icons/ri";
import { PiPlugsConnectedBold } from "react-icons/pi";
import { BiSolidReport, BiMenuAltRight } from "react-icons/bi";
import vided1 from "../assets/img/loginVid.mp4";
import Signupvid from "../assets/img/Signup.mp4";
import login1 from "../assets/img/loginNew/step1.webp";
import login2 from "../assets/img/loginNew/step2.webp";
import login3 from "../assets/img/loginNew/step2.webp";
import login4 from "../assets/img/singUpnew/step4.webp";
import active2 from "../assets/img/activebot/1.webp";
import active3 from "../assets/img/activebot/2.webp";
import trades1 from "../assets/img/trades/1.webp";
import trades2 from "../assets/img/trades/2.webp";
import trades3 from "../assets/img/trades/3.webp";
import report1 from "../assets/img/report/1.webp";
import report2 from "../assets/img/report/2.webp";
import report3 from "../assets/img/report/3.webp";
import singup1 from "../assets/img/singUpnew/step1.webp";
import singup0 from "../assets/img/singUpnew/step2.webp";
import singup2 from "../assets/img/singUpnew/step2.webp";
import singup4 from "../assets/img/singUpnew/step3.webp";
import sub1 from "../assets/img/singUpnew/step4.webp";
import sub2 from "../assets/img/subscription/step2.webp";
import sub3 from "../assets/img/subscription/step3.webp";
import sub4 from "../assets/img/subscription/step4.webp";
import viewAPi1 from "../assets/img/vewApi/step1.jpg";
import viewAPi2 from "../assets/img/vewApi/step2.jpg";
import viewAPi3 from "../assets/img/vewApi/step3.jpg";
import viewAPi4 from "../assets/img/vewApi/step4.jpg";
import { cardData } from "./data";
import logo from "../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { SiProbot } from "react-icons/si";
import { BsBarChart } from "react-icons/bs";
import { Drawer } from "antd";
import Guidecarddata from "./Guidecarddata";
import { ImProfile } from "react-icons/im";
import { PiPasswordFill, PiSwap, PiUserSwitchFill, PiHandWithdrawFill } from "react-icons/pi";

import { FaMoneyBillTransfer } from "react-icons/fa6";
import { TfiWallet } from "react-icons/tfi";
import { TbTransferIn } from "react-icons/tb";

import { FaExternalLinkAlt } from "react-icons/fa";

import deposit1 from "../assets/img/howtodeposit/2.webp";
import deposit2 from "../assets/img/howtodeposit/3.webp";
import deposit3 from "../assets/img/howtodeposit/4.webp";
import deposit4 from "../assets/img/howtodeposit/5.webp";
import deposit5 from "../assets/img/howtodeposit/6.webp";
import deposit6 from "../assets/img/howtodeposit/7.webp";
import deposit7 from "../assets/img/howtodeposit/8.webp";

import kyc1 from "../assets/img/kyc/2.webp";
import kyc2 from "../assets/img/kyc/3.webp";
import kyc3 from "../assets/img/kyc/4.webp";
import kyc4 from "../assets/img/kyc/5.webp";
import kyc5 from "../assets/img/kyc/6.webp";
import kyc6 from "../assets/img/kyc/7.webp";

import tp1 from "../assets/img/transactionpass/2.webp";
import tp2 from "../assets/img/transactionpass/3.webp";
import tp3 from "../assets/img/transactionpass/4.webp";
import tp4 from "../assets/img/transactionpass/5.webp";
import tp5 from "../assets/img/transactionpass/6.webp";
import tp6 from "../assets/img/transactionpass/7.webp";
import tp7 from "../assets/img/transactionpass/8.webp";
import tp8 from "../assets/img/transactionpass/9.webp";
import tp9 from "../assets/img/transactionpass/10.webp";
import tp10 from "../assets/img/transactionpass/11.webp";
import tp11 from "../assets/img/transactionpass/12.webp";



import wa1 from "../assets/img/walletaddress/2.webp";
import wa2 from "../assets/img/walletaddress/3.webp";
import wa3 from "../assets/img/walletaddress/4.webp";
import wa4 from "../assets/img/walletaddress/5.webp";
import wa5 from "../assets/img/walletaddress/6.webp";
import wa6 from "../assets/img/walletaddress/7.webp";




import tr1 from "../assets/img/transferreward/2.webp";
import tr2 from "../assets/img/transferreward/3.webp";
import tr3 from "../assets/img/transferreward/4.webp";
import tr4 from "../assets/img/transferreward/5.webp";
import tr5 from "../assets/img/transferreward/6.webp";


import p2p1 from "../assets/img/p2p/2.webp";
import p2p2 from "../assets/img/p2p/3.webp";
import p2p3 from "../assets/img/p2p/4.webp";
import p2p4 from "../assets/img/p2p/5.webp";
import p2p5 from "../assets/img/p2p/6.webp";
import p2p6 from "../assets/img/p2p/7.webp";


import w1 from "../assets/img/withdrawal/2.webp";
import w2 from "../assets/img/withdrawal/3.webp";
import w4 from "../assets/img/withdrawal/5.webp";
import w5 from "../assets/img/withdrawal/6.webp";
import w6 from "../assets/img/withdrawal/7.webp";
import w7 from "../assets/img/withdrawal/8.webp";
import w8 from "../assets/img/withdrawal/9.webp";
import GoogleTranslate from "../Component/GoogleTranslate";

const Guide = () => {
  const [selected, setSelected] = useState(0);
  const [selectedTitle, setSelectedTitle] = useState("signin");
  const [mappingData, setMappingData] = useState([]);
  const [checkTech, setCheckTech] = useState();
  const [showPage, setShowPage] = useState(true);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const navigate = useNavigate();

  const showDrawer = () => {
    setOpen(true);
  };
  const showDrawer2 = () => {
    setOpen2(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onClose2 = () => {
    setOpen2(false);
  };

  const [selectedTab, setSelectedTab] = useState("App");

  // if(selectedTab === "Trade"){
  //   setSelectedTitle("kyc")
  // }

  // const OdecentSidebar = [
  //   // Define Odecent specific topics here
  //   {
  //     topic: "SIGN UP PAGE",
  //     icon: <BsPersonFillAdd className="text-xl " />,
  //     id: "signin",
  //   },
  //   {
  //     topic: "LOGIN PAGE",
  //     icon: <AiOutlineLogin className="text-xl " />,
  //     id: "login",
  //   },
  //   {
  //     topic: "TOPUP",
  //     icon: <FaMoneyBillTransfer className="text-xl " />,
  //     id: "topup",
  //   },

  //   {
  //     topic: "BUY SUBSCRIPTION",
  //     icon: <TbPremiumRights className="text-xl " />,
  //     id: "subs",
  //   },

  //   {
  //     topic: "KYC",
  //     icon: <ImProfile className="text-xl " />,
  //     id: "kyc",
  //   },
  //   // {
  //   //   topic: "TELEGRAM",
  //   //   icon: <BiLogoTelegram className="text-xl " />,
  //   // },

  //   {
  //     topic: "TRANSACTION PASSWORD",
  //     icon: <PiPasswordFill className="text-xl " />,
  //     id: "transactionpassword",
  //   },

  //   // {
  //   //   topic: "CONNECT TO LIVE API",
  //   //   icon: <PiPlugsConnectedBold className="text-xl " />,
  //   //   id: "liveapi",
  //   // },
  //   // {
  //   //   topic: "VIEW API",
  //   //   icon: <AiOutlineFundView className="text-xl " />,
  //   // },
  //   // {
  //   //   topic: "COPYING A BOT",
  //   //   icon: <RiRobot2Line className="text-xl " />,
  //   //   id: "createbot",
  //   // },

  //   {
  //     topic: "WALLET ADDRESS",
  //     icon: <TfiWallet className="text-xl " />,
  //     id: "walletaddress",
  //   },

  //   {
  //     topic: "TRANSFER REWARD",
  //     icon: <PiSwap className="text-xl " />,
  //     id: "transferreward",
  //   },

  //   {
  //     topic: "P2P",
  //     icon: <PiUserSwitchFill className="text-xl " />,
  //     id: "p2p",
  //   },

  //   {
  //     topic: "WITHDRAWAL",
  //     icon: <PiHandWithdrawFill className="text-xl " />,
  //     id: "withdrawal",
  //   },
  // ];

  // const TradeSidebar = [
  //   // Define Trade specific topics here
  //   {
  //     topic: "SIGN UP PAGE",
  //     icon: <BsPersonFillAdd className="text-xl " />,
  //     id: "signin",
  //   },
  //   {
  //     topic: "LOGIN PAGE",
  //     icon: <AiOutlineLogin className="text-xl " />,
  //     id: "login",
  //   },
  //   {
  //     topic: "TOPUP",
  //     icon: <FaMoneyBillTransfer className="text-xl " />,
  //     id: "topup",
  //   },

  //   {
  //     topic: "BUY SUBSCRIPTION",
  //     icon: <TbPremiumRights className="text-xl " />,
  //     id: "subs",
  //   },
  //   {
  //     topic: "CONNECT TO PAPER API",
  //     icon: <AiFillApi className="text-xl " />,
  //     id: "paperapi",
  //   },
  //   {
  //     topic: "CONNECT TO LIVE API",
  //     icon: <PiPlugsConnectedBold className="text-xl " />,
  //     id: "liveapi",
  //   },
  //   {
  //     topic: "COPYING A BOT",
  //     icon: <RiRobot2Line className="text-xl " />,
  //     id: "createbot",
  //   },
  //   {
  //     topic: "RISK MATRIX",
  //     icon: <TbPremiumRights className="text-xl " />,
  //     id: "riskm",
  //   },
  //   {
  //     topic: "ACTIVE BOTS",
  //     icon: <SiProbot className="text-xl " />,
  //     id: "activebot",
  //   },
  //   {
  //     topic: "TRADES",
  //     icon: <BsBarChart className="text-xl " />,
  //     id: "trade",
  //   },
  //   {
  //     topic: "REPORT",
  //     icon: <BiSolidReport className="text-xl " />,
  //     id: "report",
  //   },
  // ];

  const OdecentSidebar = [
    { topic: "SIGN UP PAGE", icon: <BsPersonFillAdd className="text-xl " />, id: "signin" },
    { topic: "LOGIN PAGE", icon: <AiOutlineLogin className="text-xl " />, id: "login" },
    { topic: "TOPUP", icon: <FaMoneyBillTransfer className="text-xl " />, id: "topup" },
    { topic: "BUY SUBSCRIPTION", icon: <TbPremiumRights className="text-xl " />, id: "subs" },
    { topic: "KYC", icon: <ImProfile className="text-xl " />, id: "kyc" },
    { topic: "TRANSACTION PASSWORD", icon: <PiPasswordFill className="text-xl " />, id: "transactionpassword" },
    { topic: "WALLET ADDRESS", icon: <TfiWallet className="text-xl " />, id: "walletaddress" },
    { topic: "TRANSFER REWARD", icon: <PiSwap className="text-xl " />, id: "transferreward" },
    { topic: "P2P", icon: <PiUserSwitchFill className="text-xl " />, id: "p2p" },
    { topic: "WITHDRAWAL", icon: <PiHandWithdrawFill className="text-xl " />, id: "withdrawal" },
  ];

  const TradeSidebar = [
    { topic: "SIGN UP PAGE", icon: <BsPersonFillAdd className="text-xl " />, id: "signin" },
    { topic: "LOGIN PAGE", icon: <AiOutlineLogin className="text-xl " />, id: "login" },
    { topic: "TOPUP", icon: <FaMoneyBillTransfer className="text-xl " />, id: "topup" },
    { topic: "BUY SUBSCRIPTION", icon: <TbPremiumRights className="text-xl " />, id: "subs" },
    { topic: "CONNECT TO PAPER API", icon: <AiFillApi className="text-xl " />, id: "paperapi" },
    { topic: "CONNECT TO LIVE API", icon: <PiPlugsConnectedBold className="text-xl " />, id: "liveapi" },
    { topic: "COPYING A BOT", icon: <RiRobot2Line className="text-xl " />, id: "createbot" },
    { topic: "RISK MATRIX", icon: <TbPremiumRights className="text-xl " />, id: "riskm" },
    { topic: "ACTIVE BOTS", icon: <SiProbot className="text-xl " />, id: "activebot" },
    { topic: "TRADES", icon: <BsBarChart className="text-xl " />, id: "trade" },
    { topic: "REPORT", icon: <BiSolidReport className="text-xl " />, id: "report" },
  ];


  const topics = selectedTab === "App" ? OdecentSidebar : TradeSidebar;



  const primaryData = [
    {
      filterTitle: "LOGIN PAGE",
      title: "LOGIN PAGE",
      id: "login",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Login. Follow these
            steps carefully, and refer to the images provided at each step for
            visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md leading-7 font-binance-plex">
              Step 1: Open the Login Page
            </p>
            <p className="text-md leading-7 font-binance-plex">
              Step 2: Provide Your Credentials
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Click{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-SecondaryColor underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "}
            </p>

            <p className="text-md font-binance-plex leading-7">
              Step 4: Access Your Account
            </p>
          </div>
          <hr class="border-2 border-gray-400 my-4" />
          <div>
            {selectedTitle === "login" ? (
              <div className="my-4 flex justify-center" id="login">
                <video controls width="640" height="360" id="login">
                  <source src={vided1} type="video/mp4" />
                </video>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">1:</span> Access the Login Page
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              Begin by navigating to the Odecent login page. Enter your username
              and password in the designated fields and click on the "Login"
              button to access your account.
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-SecondaryColor underline font-semibold ml-1"
              >
                app.odecent.com
              </a>
            </p>
            <img src={login1} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">2:</span>
              Provide Your Credentials
            </p>
            <p className="text-md font-binance-plex leading-7">
              Enter your username & password in the designated fields.
            </p>
            <img src={login3} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">3:</span>Click Login:
            </p>
            <p className="text-md font-binance-plex leading-7">
              Click the{" "}
              <span className="text-colorAccent  font-semiboldbold">
                "Login"{" "}
              </span>{" "}
              button to submit your credentials for verification.
            </p>
            <img src={login2} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">4:</span>
              Access Your Account
            </p>
            <p className="text-md font-binance-plex leading-7">
              If your credentials are correct, you'll be granted access to your
              account's{" "}
              <span className="text-colorAccent">Odecent Dashboard</span>.
            </p>
            <img src={login4} alt="" className="my-4" />
          </div>
        </div>,
      ],
    },
    {
      filterTitle: "SIGN UP PAGE",
      title: "SIGN UP PAGE",
      id: "signin",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to SignUp. Follow these
            steps carefully, and refer to the images provided at each step for
            visual guidance.
          </p>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1: Access the Sign-Up Page
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: Fill in Basic Information
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Click Sign-Up
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Verify Your Email
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Access Your New Account
            </p>
          </div>
          <div className="my-4 flex justify-center ">
            <video
              className="border-2 border-SecondaryColor"
              controls
              width="640"
              height="360"
            >
              <source src={Signupvid} type="video/mp4" />
            </video>
          </div>
          <hr class="border-2 border-gray-400 my-4" />
          <p className=" text-lg leading-6 mt-2 font-bold text-SecondaryColor">
            1. Access the Sign-Up Page from the referral Link.
          </p>

          <img src={singup1} alt="" className="my-4 w-[300px] h-[400px]" />
          <p className="font-bold text-SecondaryColor text-lg leading-6 mt-2">
            2. Fill in Basic Information
          </p>

          <p className="text-md font-binance-plex leading-7">
            Choose a unique username, enter your email, name,country, password,
            and confirm your password. Accept Terms & conditions & Accept
            Captcha.
          </p>

          <img src={singup0} alt="" className="my-4 w-[300px] h-[400px]" />
          <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
            3. Click Sign Up:{" "}
          </p>

          <p className="text-md font-binance-plex leading-7">
            Click the <span className="text-colorAccent ">"Sign Up"</span>{" "}
            button to start the registration process.
          </p>

          <img src={singup2} alt="" className="my-4 w-[300px] h-[400px]" />
          <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
            4. Verify Your Email{" "}
          </p>

          <p className="text-md font-binance-plex leading-7">
            Check your email for a verification link and input the OTP (one-time
            password) to gain access to the platform.
          </p>

          <img src={singup4} alt="" className="my-4  w-[300px] h-[400px]" />
          <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
            5. Access Your New Account
          </p>

          <p className="text-md font-binance-plex leading-7">
            After email verification, you can log in with your chosen username &
            password to access your new account.
          </p>

          <img src={login3} alt="" className="my-4" />
        </div>
      ],
    },
    // {
    //   filterTitle: "TOPUP",
    //   title: "HOW TO TOPUP",

    //   mappingData: [
    //     <p className="text-md font-binance-plex leading-7">
    //       Step 1 :On the{" "}
    //       <span className="text-colorAccent">Odecent Dashboard</span>, locate
    //       and click the <span className="text-colorAccent ">"Top-Up"</span>{" "}
    //       button.
    //     </p>,
    //     <p className="text-md font-binance-plex leading-7">
    //       Step 2 :Choose the cryptocurrency or coin you want to use for the
    //       payment.
    //     </p>,
    //     <p className="text-md font-binance-plex leading-7">
    //       Step 3 :Enter the desired amount you wish to top up and then click the
    //       <span className="text-colorAccent ">"Pay Now"</span> button.
    //     </p>,
    //     <p className="text-md font-binance-plex leading-7">
    //       Step 4 :After clicking{" "}
    //       <span className="text-colorAccent ">"Pay Now"</span>, you will receive
    //       a payment barcode.
    //     </p>,
    //     <p className="text-md font-binance-plex leading-7">
    //       Step 5 :Proceed to make the payment as instructed by scanning or
    //       presenting the payment barcode.
    //     </p>,
    //     <p className="text-md font-binance-plex leading-7">
    //       Step 6 :Wait for confirmation of your payment.
    //     </p>,
    //   ],
    // },
    {
      filterTitle: "TRANSFER REWARD",
      title: "TRANSFER REWARD",
      id: "transferreward",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Transfer Reward. Follow these
            steps carefully, and refer to the images provided at each step for
            visual guidance.
          </p>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md leading-7 font-binance-plex">
              Step 1: Access the  <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "} Page
            </p>
            <p className="text-md leading-7 font-binance-plex">
              Step 2: Navigate to the Transfer Wallet Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Select the Rewards Wallet
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Enter the Amount to Transfer
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Transfer the Funds
            </p>
            <hr class="border-2 border-gray-400 my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              1. Access the Login Page
            </p>
            <p className="text-md font-binance-plex leading-7">
              Start by navigating to the Odecent login page{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">app.odecent.com</span>
                <FaExternalLinkAlt />
              </a>{" "}
              . Enter your username and password in the designated fields, then
              click <span className="text-SecondaryColor font-bold text-lg">Login</span> to access your account.
            </p>
            <img src={login1} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              2. Navigate to the Transfer Wallet Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Once logged in, go to User <span className="text-SecondaryColor font-bold text-lg">  Dashboard ➔ Wallet ➔ Transfer Wallet</span>. This
              section is where you can manage and transfer your rewards.
            </p>
            <img src={tr1} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              3. Select the Rewards Wallet
            </p>

            <p className="text-md font-binance-plex leading-7">
              In the Transfer Wallet section, select the rewards wallet from which
              you wish to transfer funds. You can transfer balances from the
              following reward types: Direct Bonus Network Bonus Rank Bonus Matching
              Bonus Weekly Reward.
            </p>

            <img src={tr2} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              4. Enter the Amount to Transfer
            </p>
            <p className="text-md font-binance-plex leading-7">
              Enter the desired amount you want to transfer from your selected
              rewards wallet to your withdrawal wallet. Make sure the amount is
              accurate before proceeding.<br />
              <span className="text-SecondaryColor font-bold text-lg">Minimum $20 and Maximum $10000</span>
            </p>
            <img src={tr3} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              5. Transfer the Funds
            </p>
            <p className="text-md font-binance-plex leading-7">
              Once you've entered the desired amount, click the "Transfer" button to
              move the funds from your selected rewards wallet to your withdrawal
              wallet.
            </p>
            <img src={tr4} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">

            <p className="text-lg font-bold font-binance-plex leading-7">
              Note: There is a "Transfer All" button available for your convenience.
              By clicking this button, you can transfer the entire balance from all
              your rewards wallets to your withdrawal wallet in one step
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-7">
              Success! Your rewards have now been successfully transferred to your
              withdrawal wallet
            </p>
            <img src={tr5} alt="" className="my-4" />
          </div>

        </div>
      ],
    },

    {
      filterTitle: "WALLET ADDRESS",
      title: "WALLET ADDRESS",
      id: "walletaddress",
      mappingData: [

        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Wallet Address. Follow these
            steps carefully, and refer to the images provided at each step for
            visual guidance.
          </p>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">



            <p className="text-md leading-7 font-binance-plex">
              Step 1: Access the  <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "} Page
            </p>
            <p className="text-md leading-7 font-binance-plex">
              Step 2: Navigate to the Update Wallet Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Click on Update Wallet
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Select the Preferred Network and Enter Your Wallet Address
              Choose the network you want to use from the options provided either
              BEP-20 or TRC-20. Carefully paste your new wallet address into the
              designated field.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Submit Your Wallet Address
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 6: Verify with OTP
            </p>
          </div>
          <hr class="border-2 border-gray-400 my-4" />

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 font-binance-plex">
              Step 1: Access the <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "} Page
            </p>
            <p className="text-md font-binance-plex leading-7">
              Start by navigating to the Odecent login page. Enter your <span className="text-SecondaryColor text-lg font-bold capitalize">username </span> &
              <span className="text-SecondaryColor text-lg font-bold capitalize"> password </span> in the appropriate fields, then click <span className="text-SecondaryColor text-lg font-bold uppercase"> Login </span> to access your
              account
            </p>
            <img src={login1} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 font-binance-plex">
              2. Navigate to the Update Wallet Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Once logged in, go to User <span className="text-SecondaryColor text-lg font-bold uppercase"> Dashboard ➔ Profile ➔ Update Wallet </span>. This
              will take you to the section where you can manage your <span className="text-SecondaryColor text-lg font-bold capitalize "> wallet address</span>.
            </p>
            <img src={wa1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 font-binance-plex">
              3. Click on Update Wallet:
            </p>
            <p className="text-md font-binance-plex leading-7">
              Click the <span className="text-SecondaryColor text-lg font-bold uppercase"> Update Wallet </span> button to start the process of updating your
              <span className="text-SecondaryColor text-lg font-bold capitalize">      wallet address</span>.
            </p>
            <img src={wa2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 font-binance-plex">
              4. Select the Preferred Network and Enter Your Wallet Address
            </p>
            <p className="text-md font-binance-plex leading-7">
              Choose the network you want to use from the options provided either <span className="text-SecondaryColor text-lg font-bold ">
                BEP-20 or TRC-20 </span>. Carefully paste your new wallet address into the
              designated field.
            </p>
            <img src={wa3} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 font-binance-plex">
              5. Submit Your Wallet Address
            </p>
            <p className="text-md font-binance-plex leading-7">
              After entering your <span className="text-SecondaryColor text-lg font-bold uppercase">wallet address</span>, click the <span className="text-SecondaryColor text-lg font-bold ">Update</span> button to
              proceed.
            </p>
            <img src={wa4} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 font-binance-plex">
              6. Verify with OTP
            </p>
            <p className="text-md font-binance-plex leading-7">
              An <span className="text-SecondaryColor text-lg font-bold "> OTP (One-Time Password) </span>will be sent to your registered email
              address. Retrieve the <span className="text-SecondaryColor text-lg font-bold uppercase"> OTP </span> from your email, enter it in the provided
              field, and click <span className="text-SecondaryColor text-lg font-bold "> Verify & Submit </span> to confirm the update.
            </p>
            <img src={wa5} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 font-binance-plex">Success!</p>
            <p className="text-md font-binance-plex leading-7">
              Your <span className="text-SecondaryColor text-lg font-bold uppercase"> wallet address </span> has now been successfully updated.
            </p>
            <img src={wa6} alt="" className="my-4" />
          </div>
        </div>
      ],
    },

    {
      filterTitle: "TRANSACTION PASSWORD",
      title: "Transaction Password",
      id: "transactionpassword",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Create Transaction
            Password. Follow these steps carefully, and refer to the images
            provided at each step for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold">How to Create a Transaction Password </p>

            <p className="text-md leading-7 font-binance-plex">
              Step 1: Access the <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "} Page
            </p>
            <p className="text-md leading-7 font-binance-plex">
              Step 2:Navigate to the Transaction Password Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Create a Transaction Password
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Set a Strong Password
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Submit Your Password
            </p>
          </div>

          <hr class="border-2 border-gray-400 my-4" />

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              1. Open the Login Page
            </p>
            <p className="text-md font-binance-plex leading-7">
              Go to the Login Page from at{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">app.odecent.com</span>
                <FaExternalLinkAlt />
              </a>{" "}
            </p>
            <img src={login1} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              2. Navigate to the Transaction Password Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Once logged in, go to User Dashboard ➔ Profile ➔ Transaction
              Password. This will take you to the section where you can create
              or manage your transaction password
            </p>
            <img src={tp1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              3.Create a Transaction Password:
            </p>
            <p className="text-md font-binance-plex leading-7">
              Click on the "Create Password" button to begin setting up your
              transaction password.
            </p>
            <img src={tp2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              4. Set a Strong Password
            </p>
            <p className="text-md font-binance-plex leading-7">
              Create a password that meets the following criteria:
              <br />
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine
                  size={20}
                  className="text-green-500"
                />{" "}
                Minimum of 6 characters.{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains lowercase letters.{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains uppercase letters{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains digits.{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains special characters.{" "}
              </div>
              As you input your password, the system will indicate the strength
              of your password. Ensure that it meets all the required conditions
              to make it strong.
            </p>
            <img src={tp3} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              5. Submit Your Password
            </p>
            <p className="text-md font-binance-plex leading-7">
              Once your password is strong and meets all the criteria, click the
              "Submit" button
            </p>
            <img src={tp4} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2"> Success</p>
            <p className="text-md font-binance-plex leading-7">
              Congratulations! Your transaction password has been successfully
              created
            </p>
            <img src={tp5} alt="" className="my-4" />
          </div>

          <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
            <p className="text-xl font-semibold text-gray-800 mb-4">
              This guide will walk you through the steps to Reset Transaction
              Password. Follow these steps carefully, and refer to the images
              provided at each step for visual guidance.
            </p>

            <div className="bg-white p-4 rounded-lg shadow-md mb-4">
              <p className="font-bold">How to Reset a Transaction Password </p>

              <p className="text-md leading-7 font-binance-plex">
                Step 1: Navigate to the Transaction Password Section
              </p>
              <p className="text-md leading-7 font-binance-plex">
                Step 2: Click on Reset Password
              </p>
              <p className="text-md font-binance-plex leading-7">
                Step 3: Create a New Strong Password
              </p>
              <p className="text-md font-binance-plex leading-7">
                Step 4: Request and Enter OTP
              </p>
              <p className="text-md font-binance-plex leading-7">
                Step 5: Submit and Confirm
              </p>
            </div>
            <hr class="border-2 border-gray-400 my-4" />

            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              1. Navigate to the Transaction Password Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Go to User Dashboard ➔ Profile ➔ Transaction Password to manage
              your password settings
            </p>
            <img src={tp6} alt="" className="my-4" />
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              2. Click on Reset Password
            </p>
            <p className="text-md font-binance-plex leading-7">
              Click the "Reset Password" button to start the password reset
              process.
            </p>
            <img src={tp7} alt="" className="my-4" />
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              3.Create a New Strong Password:
            </p>
            <p className="text-md font-binance-plex leading-7">
              Enter a new password that meets the following criteria:
              <br />
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine
                  size={20}
                  className="text-green-500"
                />{" "}
                Minimum of 6 characters.{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains lowercase letters.{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains uppercase letters{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains digits.{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains special characters.{" "}
              </div>
              Ensure the password is strong according to the system’s
              indicators.
            </p>
            <img src={tp8} alt="" className="my-4" />
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              4. Request and Enter OTP
            </p>
            <p className="text-md font-binance-plex leading-7">
              Click the "Send OTP" button to receive a One-Time Password (OTP)
              via your registered email. Check your email, retrieve the OTP, and
              enter it in the designated field for security authentication
            </p>
            <img src={tp9} alt="" className="my-4" />
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              5. Submit and Confirm
            </p>
            <p className="text-md font-binance-plex leading-7">
              After entering the OTP, click the "Submit" button to finalize the
              reset of your transaction password
            </p>
            <img src={tp10} alt="" className="my-4" />

            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              Important Note:
            </p>
            <p className="text-md font-binance-plex leading-7">
              You can only reset your transaction password three times. Ensure
              you remember your new password to avoid reaching this limit.
            </p>
            <img src={tp11} alt="" className="my-4" />
          </div>
        </div>,
      ],
    },
    {
      filterTitle: "TOPUP",
      title: "How to Deposit Funds Using CoinPayment",
      id: "topup",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to deposit funds into
            your Odecent wallet using CoinPayment. Follow these steps carefully,
            and refer to the images provided at each step for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 ">
              1: Access the  <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "} Page
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              Begin by navigating to the Odecent login page. Enter your  <span className="text-SecondaryColor text-lg font-bold capitalize"> username</span>
              &  <span className="text-SecondaryColor text-lg font-bold capitalize"> password </span> in the designated fields and click on the  <span className="text-SecondaryColor text-lg font-bold capitalize">Login </span>
              button to access your account.

            </p>
            <img
              src={login1}
              alt="Login Page"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">2:</span> Navigate to the Top-Up Section
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              Once logged in, locate the  <span className="text-SecondaryColor text-lg font-bold capitalize">Top-Up </span> button on the top-right corner
              of the dashboard. Click this button to initiate the   <span className="text-SecondaryColor text-lg font-bold capitalize">deposit
                process</span>.
            </p>
            <img
              src={deposit1}
              alt="Top-Up Section"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">3:</span> Enter the Deposit Amount
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              You can now specify the amount you wish to deposit. Enter the
              desired amount manually or select one of the prefixed amounts
              displayed on the page. Once done, click the  <span className="text-SecondaryColor text-lg font-bold capitalize">Next</span> button to
              proceed.
            </p>
            <img
              src={deposit2}
              alt="Deposit Amount"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">4:</span> Choose Your Preferred
              Cryptocurrency
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              You will be presented with a list of  <span className="text-SecondaryColor text-lg font-bold capitalize"> supported cryptocurrencies</span>.
              Select the  <span className="text-SecondaryColor text-lg font-bold capitalize">coin</span> you want to use for your  <span className="text-SecondaryColor text-lg font-bold capitalize"> deposit</span>.<br /> Ensure you
              review the   <span className="text-SecondaryColor text-lg font-bold capitalize">coin’s exchange rate</span>, which will be shown on the page.
            </p>
            <img
              src={deposit3}
              alt="Cryptocurrency Selection"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">5:</span> Review the Conversion and Proceed
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              After selecting your  <span className="text-SecondaryColor text-lg font-bold capitalize"> cryptocurrency</span>, check the  <span className="text-SecondaryColor text-lg font-bold capitalize"> conversion rate</span> for
              the amount you wish to deposit. The conversion details, including
              any  <span className="text-SecondaryColor text-lg font-bold capitalize">  applicable fees</span>, will be displayed. Once confirmed, click the
              <span className="text-SecondaryColor text-lg font-bold capitalize"> CoinPayment</span> button to proceed to the payment page.
            </p>
            <img
              src={deposit4}
              alt="Conversion Review"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">6:</span> Complete the Payment on
              CoinPayment
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              You will be redirected to the  <span className="text-SecondaryColor text-lg font-bold capitalize"> CoinPayment page </span>, where you will see
              the exact  <span className="text-SecondaryColor text-lg font-bold capitalize">amount </span> of the selected  <span className="text-SecondaryColor text-lg font-bold capitalize"> cryptocurrency </span> you need to
              transfer. <br />A  <span className="text-SecondaryColor text-lg font-bold capitalize">barcode </span>will be provided for easy payment.<br /> Be aware
              to add some additional funds may be required to  <span className="text-SecondaryColor text-lg font-bold capitalize">cover gas fees</span>.<br /> If
              you don't add an additional fund  for gas fees, the amount credited to your
              <span className="text-SecondaryColor text-lg font-bold capitalize">   Odecent wallet </span> will be less after fees are deducted.
            </p>
            <img
              src={deposit5}
              alt="CoinPayment Page"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">7:</span> Wait for Payment Confirmation
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              After completing the   <span className="text-SecondaryColor text-lg font-bold capitalize">payment</span>, you will need to wait for
              CoinPayment to confirm the transaction. You will receive email
              updates about your <span className="text-SecondaryColor text-lg font-bold capitalize"> payment status</span>.
            </p>
            <img
              src={deposit6}
              alt="Payment Confirmation"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">8:</span> Check Payment Status
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              Once payment is  <span className="text-SecondaryColor text-lg font-bold capitalize"> confirmed </span>, you can monitor the status by
              navigating to your  <span className="text-SecondaryColor text-lg font-bold capitalize"> dashboard</span>.<br /> <p className="text-md font-binance-plex leading-7">
                Once logged in, go to  <span className="text-SecondaryColor text-lg font-bold uppercase">User Dashboard ➔ Transaction
                  ➔ Deposit Transaction Table</span></p>.In the last column labeled  <span className="text-SecondaryColor font-bold">Status</span> you will see the current status of your <br />  <span className="text-SecondaryColor font-bold">Transaction:</span>  <span className="text-yellow-600 font-bold">Pending</span>,  <span className="text-red-600 font-bold">Failed</span>, or  <span className="text-green-600 font-bold">Successful</span><br />

              Additionally, a link to the <span className="text-SecondaryColor text-lg font-bold capitalize"> CoinPayment </span> status page
              will be provided.
            </p>
            <img
              src={deposit7}
              alt="Payment Status"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>
        </div>,
      ],
    },

    {
      filterTitle: "BUY SUBSCRIPTION",
      title: "HOW TO BUY SUBSCRIPTION",
      id: "subs",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Buy Subscription.
            Follow these steps carefully, and refer to the images provided at
            each step for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1: Access Your Account Dashboard.
            </p>

            <p className="text-md font-binance-plex leading-7">
              Step 2: Select the Tab&nbsp;
              <span className="text-SecondaryColor font-bold"> Subscription </span>
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Choose Your Preferred Subscription.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Confirmation Message for Successful Subscription.
            </p>
          </div>
          <hr class="border-2 border-gray-400 my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-binance-plex leading-7 mt-3">
              <span className="text-SecondaryColor font-bold">
                Step 1: Log in to your Odecent account and go to the Dashboard.
              </span>
            </p>
            <img src={sub1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-binance-plex leading-7">
              <span className="text-SecondaryColor font-bold">
                Step 2: Navigate to the "Subscription" section and select the
                desired subscription from the list.
              </span>
            </p>
            <img src={sub2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-binance-plex leading-7">
              <span className="text-SecondaryColor font-bold">
                Step 3: Click "View More" to check the details, then click
                "Subscribe Now".
              </span>
            </p>
            <img src={sub3} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-binance-plex leading-7">
              <span className="text-SecondaryColor font-bold">
                Step 4: Upon successful subscription, a confirmation message
                will appear.
              </span>
            </p>
            <img src={sub4} alt="" className="my-4 w-[500px] h-[400px]" />
          </div>
        </div>,
      ],
    },

    {
      filterTitle: "KYC",
      title: "How to Complete Your KYC Verification",
      id: "kyc",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to KYC. Follow these
            steps carefully, and refer to the images provided at each step for
            visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Ensuring your account is verified through the KYC (Know Your
              Customer) process is crucial for full access to all the features
              on the Odecent platform. Follow this step-by-step guide to
              successfully complete your KYC verification. Each step is
              accompanied by images to guide you visually
            </p>

            <br />

            <p className="text-md leading-7 font-binance-plex">
              Step 1: Access the <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "} Page
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: Navigate to the KYC Section.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Open the KYC Form.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Fill Out Your Personal Details.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Verify Your Identity.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 6: Final Review and Submission.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 7: Check KYC Status.
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 font-binance-plex">
              Step 1: Access the <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "} Page
            </p>
            <p className="text-md font-binance-plex leading-7 ">
              Begin by navigating to the Odecent login page.
              <br /> Enter your username and password in the designated fields
              and click on the "Login" button to access your account.
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold"
              >
                app.odecent.com
              </a>{" "}
            </p>
            <img src={login1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              2: Navigate to the KYC Section
            </p>
            <p className="text-md flex  font-binance-plex leading-7 text-justify">
              Once logged in, go to User Dashboard ➔ Profile ➔ KYC Status. This
              will take you to the KYC section of your profile.
            </p>
            <img src={kyc1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              3. Open the KYC Form
            </p>

            <p className="text-md font-binance-plex leading-7 text-justify">
              In the KYC Status section, you will see the status of your KYC
              process. If it shows as "INCOMPLETE," click on it to open the KYC
              form.
            </p>
            <img src={kyc2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              4: Fill Out Your Personal Details
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Carefully fill out the form with your personal information. Ensure
              that all details match your official documents to avoid delays in
              the verification process.
              <br />
              <span className="font-bold">KYC Form Guide</span>: How to Fill Out
              Your Personal Details and Verify Your Identity
              <br />
              <span className="font-bold">First Name</span>: Enter your first
              name exactly as it appears on your official documents.
              <br />
              <span className="font-bold">Last Name</span>: Enter your last name
              as per your official documents.
              <br />
              <span className="font-bold">Email Address</span>: Provide a valid
              email address that you regularly check. This will be used for
              communication and verification.
              <br />
              <span className="font-bold">Date of Birth (yyyy-mm-dd)</span>:
              Enter your date of birth in the format YYYY-MM-DD. Ensure this
              matches your official documents.
              <br />
              <span className="font-bold">Gender</span>: Select your gender from
              the available options.
              <br />
              <span className="font-bold">Country</span>: This field is
              pre-filled as per your registered country.
              <br />
              <span className="font-bold">City of Residence</span>: Enter the
              city where you currently reside.
              <br />
              <span className="font-bold">Address Line 1</span>: Provide the
              first line of your address (e.g., house number, street name).
              <br />
              <span className="font-bold">Address Line 2 (Optional)</span>: You
              can provide additional address details here, such as apartment
              number or building name.
              <br />
              <span className="font-bold">Zip Code</span>: Enter the postal code
              for your address.
              <br />
              <span className="font-bold">Phone Number</span>: This field is
              pre-filled with the country code "+86." Enter your phone number
              without the country code.
            </p>
            <img src={kyc3} alt="" className="my-4" />
            <p>
              Important: Ensure that all information is accurate and matches
              your official documents. Once submitted, these details cannot be
              edited
            </p>

          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              5: Verify Your Identity
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Next, you need to upload a valid identification document to verify
              your identity.
              <br />
              <span className="font-bold">
                Identity Verification Instructions
              </span>
              <br />
              <span className="font-bold">Choose One Document to Upload </span>:
              You can upload a passport, national ID card, or driver's license.
              <br />
              <span className="font-bold">Document Format </span>: The document
              must be in image format (jpg or jpeg).
              <br />
              <span className="font-bold">File Size </span>: Ensure the file
              size is below 2MB.
              <br />
              <span className="font-bold">Criteria for Document Upload</span>:
              <span className="font-bold">Expiration </span>: The document must
              be valid and not expired.
              <br />
              <span className="font-bold">Condition </span>: The document should
              be in good condition, with all details clearly visible.
              <br />
              <span className="font-bold">Clarity </span>: Avoid any light glare
              or shadows on the document when taking the photo.
              <br />
              <span className="font-bold">Upload Instructions </span>: Upload a
              clear image of the front side of your passport or selected
              document for ID verification.
            </p>
            <img src={kyc4} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              6: Final Review and Submission
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Before submitting the form, review all the information and
              documents carefully. This step is crucial to ensure there are no
              errors that could delay the verification process. Once you are
              satisfied with everything, submit the form.
            </p>
            <img src={kyc5} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              7: Check KYC Status
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              After submission, the KYC verification process will begin. You can
              check the status of your KYC under User Dashboard ➔ Profile ➔ KYC
              Status. You will also receive email notifications about the
              progress of your verification.
            </p>
            <img src={kyc6} alt="" className="my-4" />
          </div>
        </div>,
      ],
    },

    {
      filterTitle: "CONNECT TO LIVE API",
      title: "HOW TO CONNECT TO LIVE API (BINANCE)",
      id: "liveapi",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Connect to Live API.
            Follow these steps carefully, and refer to the images provided at
            each step for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1: Create a{" "}
              <a
                href="https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072"
                target="_blank"
                className="text-colorSecondary underline font-semiboldbold"
              >
                Binance
              </a>{" "}
              Account
            </p>

            <p className="text-md font-binance-plex leading-7">
              Step 2: Verify Your Account and Access{" "}
              <a
                href="https://app.odecent.com/dashboard/api_settings"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold"
              >
                API Management
              </a>
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Create a New API Key
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Name the API
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Verify the API Keys
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 6: Access the Dashboard
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 7: Add Live API Keys
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 8: Copy Provided IP Addresses
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 9: Copy Provided IP Addresses (Duplicate)
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 10: Confirm and View IP Addresses
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 11: Save Changes
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 12: Enter API Details and Proceed
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 13: Confirm Conditions and Connect
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 14: Confirmation Pop-Up Upon Successful Connection
            </p>

            <p className="text-md font-binance-plex leading-7 mt-3">
              To allow your bot to interact with Binance, you will need to
              create an API Key. This key acts as a connection between{" "}
              <span className="text-colorAccent">Odecent Dashboard</span> and
              the exchange, enabling your bot to perform tasks such as placing
              automated orders and accessing your balance for its calculations.
              Essentially, the API Key serves as a way for your bot to
              communicate with the exchange and execute the actions necessary
              for automated trading.
            </p>
          </div>
          <hr class="border-2 border-gray-400 my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg  leading-7 mt-3">
              Step 1. If you haven't already, go to Binance's website and{" "}
              <a
                href="https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072"
                target="_blank"
                className="text-colorSecondary underline"
              >
                Create an Account
              </a>
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 2. Verify your account and get started on the API by
              navigating to API Management.
            </p>
            <img src={connectLive2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 3. Click on{" "}
              <span className="text-colorAccent">“Create API”</span>, select{" "}
              <span className="text-colorAccent">
                “System generated API Key”
              </span>
              and click on Next.
            </p>
            <img src={connectLive3} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 4. Start by naming the API something memorable such as{" "}
              <span className="text-colorAccent ">
                "<span className="text-colorAccent">Odecent </span> API"
              </span>
              .
            </p>
            <img src={connectLive4} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 5. You must verify your API Keys by using all of the
              following methods: phone number verification code, email
              verification code, and authenticator code.
            </p>
            <img src={connectLive5} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 6. Navigate to the{" "}
              <span className="text-colorAccent">Odecent Dashboard</span>.
            </p>
            <img src={login4} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 7. Click on{" "}
              <span className="text-colorAccent">"Add API"</span>
              and choose{" "}
              <span className="text-colorAccent">
                "Live API" and click the "Next" button.
              </span>
              .
            </p>
            <img src={connectLive6} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 8. Provide the necessary information, including the API Name,
              API Key, Secret Key , & Click the "Next" button to proceed
            </p>
            <img src={connectLive7} alt="" className="my-4" />
          </div>
          <p className="text-SecondaryColor font-bold text-lg leading-7">
            Step 9. Copy the provided IP addresses.
          </p>
          <img src={connectLive8} alt="" className="my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 10. Click "Confirm" to view all the IP addresses.
            </p>
            <img src={connectLive10} alt="" className="my-4" />
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 11. Save your changes by clicking the "Save" button.
            </p>
            <img src={connectLive11} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 12. Add the API Name, API Key, and Secret Key and click
              "Next" to complete the process.
            </p>
            <img src={connectLive12} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 13. Check the box to confirm the condition. & Click the
              "Connect" button to proceed.
            </p>
            <img src={connectLive13} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 14. Once the connection is established, a confirmation pop-up
              will appear.
            </p>
            <img src={connectLive14} alt="" className="my-4" />
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 15. After Clicking on Ok,you will be redirected to Bots .
            </p>
            <img src={connectLive15} alt="" className="my-4" />
          </div>
        </div>,
      ],
    },
    {
      filterTitle: "VIEW API",
      title: "HOW TO VIEW API",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1: Access Your Dashboard
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2: Locate and Access API Credentials
        </p>,
        <p className="text-md font-binance-plex leading-7">Step 3: View API</p>,
        <p className="text-md font-binance-plex leading-7">
          Step 4: Access Your API
        </p>,
        <hr class="border-2 border-gray-400 my-4" />,
        <p className="text-md font-binance-plex leading-7 mt-3">
          1. Navigate to your{" "}
          <span className="text-colorAccent">Odecent Dashboard</span>.
        </p>,
        <img src={viewAPi1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          2. Locate and click on the{" "}
          <span className="text-colorAccent ">"API CREDENTIALS"</span> option in
          the <span className="text-colorAccent">Odecent Dashboard</span>.
        </p>,
        <img src={viewAPi2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          3. After selecting{" "}
          <span className="text-colorAccent ">"API CREDENTIALS"</span>, click on
          the <span className="text-colorAccent ">"View API"</span> option.
        </p>,
        <img src={viewAPi3} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          4. You will then be able to view and access your API.
        </p>,
        <img src={viewAPi4} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "COPYING A BOT",
      title: "HOW TO COPYING A BOT",
      id: "createbot",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Copy a Bot. Follow
            these steps carefully, and refer to the images provided at each step
            for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1: Access the Dashboard
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: Choose and Copy Your Preferred Bot
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Configure Exchange and API Settings.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Select Telegram Alert Preferences"
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Set Automated Amount (Based on Risk Level)
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 6: Confirm Your Choices
            </p>
          </div>
          <hr class="border-2 border-gray-400 my-4" />

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 1: Go to the{" "}
              <span className="text-colorAccent">Odecent Dashboard</span>.
            </p>
            <img src={b1} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 2: Pick the bot you want and click on the "Copy Bot" button.
            </p>
            <img src={b2} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 3: Set up which Exchange and API you want to use.
            </p>
            <img src={bot4} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 4: Select if you want Telegram Alert
            </p>
            <img src={bot5} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 5: Automate Amount will come (If you choose a high-risk
              level, you'll use less money to protect against liquidation).
            </p>
            <img src={bot55} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 6: Accept Terms & Conditions then Click Submit.
            </p>
            <img src={botnew} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 7: Click the "Start" button, and a Confirmation popup will
              appear.
            </p>
            <img src={bot6} alt="" className="my-4" />
          </div>
        </div>,
      ],
    },
    {
      filterTitle: "CONNECT TO PAPER API",
      title: "HOW TO CONNECT TO PAPER API",
      id: "paperapi",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Connect Paper API.
            Follow these steps carefully, and refer to the images provided at
            each step for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1: Login & Go to the Trade Dashboard
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2. Click on the API CREDENTIALS ,Select Exchange button and
              prefer Paper Mode.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Provide the Required Information and Proceed
            </p>

            <p className="text-md font-binance-plex leading-7">
              Step 4: Confirmation Popup
            </p>
          </div>
          <hr class="border-2 border-gray-400 my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 1. Go to the{" "}
              <span className="text-colorAccent">Odecent Dashboard</span>.
            </p>
            <img src={connectpaper1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 2. Click on the{" "}
              <span className="text-colorAccent">"API CREDENTIALS"</span>
              ,Select Exchange button and prefer Paper Mode.
            </p>
            <img src={connectpaper2} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 3. Provide the necessary information, including the API Name,
              Amount in USDT & Click the "Next" button to proceed.
            </p>
            <img src={connectpaper4} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 4. A Confirmation popup will appear.
            </p>
            <img src={connectpaper5} alt="" className="my-4" />
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 5. After Clicking on Ok,you will be redirected to Bots .
            </p>
            <img src={connectLive15} alt="" className="my-4" />
          </div>
        </div>,
      ],
    },

    {
      filterTitle: "RISK MATRIX",
      title: "HOW TO SETTING YOUR RISK MATRIX",
      id: "riskm",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Risk Matrix. Follow
            these steps carefully, and refer to the images provided at each step
            for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1: Access the Dashboard and Locate the 'Bot' Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: Copy Your Chosen Bot
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Choose Your Risk Metrics
            </p>
          </div>
          <hr class="border-2 border-gray-400 my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 1. Go to the{" "}
              <span className="text-colorAccent">Odecent Dashboard</span> and
              look for the "Bot" section.
            </p>
            <img src={report1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 2. Pick the bot you want and click on the{" "}
              <a
                href="https://app.odecent.com/dashboard/botmain"
                target="_blank"
                className="text-colorAccent  font-semiboldbold"
              >
                {" "}
                "Copy Bot"
              </a>{" "}
              button.
            </p>
            <img src={b2} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 3. Select your risk metrics
            </p>
            <img src={bot55} alt="" className="my-4" />
          </div>

          <div className="flex justify-around ">
            <div>
              <div className="bg-white p-4 rounded-lg shadow-md mb-4">
                <div className="grid grid-cols-1 items-center">
                  <p className="text-lg font-bold  font-binance-plex leading-7 mt-3">
                    What is the risk parameter for future?
                  </p>
                  <img src={riskfuture} alt="" className="w-[550px]" />
                </div>

                <div>
                  <ul>
                    <li className="text-sm">
                      <b>Low:</b> To get started, just copy the bot and invest a
                      minimum of 127 USDT for low risk. Our algorithm strategies
                      are set to protect liquidation till the market collapses
                      up to 127%.
                      <br></br>
                      <b>Medium:</b>
                      To get started, just copy the bot and invest a minimum of
                      113.5 USDT for medium risk. Our algorithm strategies are
                      set to protect liquidation till the market collapses up to
                      113.5%.
                      <br></br>
                      <b>High:</b> To get started, just copy the bot and invest
                      a minimum of 25 USDT for high risk. Our algorithm
                      strategies are set to protect liquidation till the market
                      collapses up to 25%.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-md mb-4">
                <div className="grid grid-cols-1 ">
                  <p className="text-lg font-bold  font-binance-plex leading-7 mt-3">
                    {" "}
                    What is the risk parameter for spot?
                  </p>
                  <img src={riskspot} alt="" className="w-[550px]" />

                  <div>
                    <h4 className="mt-3 font-bold">
                      Select your risk level for spot:
                    </h4>
                    <ul>
                      <li className="text-sm">
                        <b>Low: </b>To get started, just copy the bot and invest
                        a minimum of 1270 USDT for low risk. Our algorithm
                        strategies are set to protect liquidation till the
                        market collapses up to 127%.
                        <br></br> <b>Medium:</b>
                        To get started, just copy the bot and invest a minimum
                        of 1135 USDT for medium risk. Our algorithm strategies
                        are set to protect liquidation till the market collapses
                        up to 113.5%. <br></br>
                        <b>High:</b> To get started, just copy the bot and
                        invest a minimum of 250 USDT for high risk. Our
                        algorithm strategies are set to protect liquidation till
                        the market collapses up to 25%.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>,
      ],
    },
    {
      filterTitle: "TELEGRAM",
      title: "HOW TO CONNECT TO TELEGRAM",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1: Access{" "}
          <a
            href="https://beta.Odecent Dashboard.ai/telegram"
            target="_blank"
            className="text-colorAccent underline font-semiboldbold"
          >
            Telegram
          </a>{" "}
          in the Top Right Corner
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2: Connect with{" "}
          <span className="text-colorAccent">Odecent Dashboard</span> Bot
        </p>,

        <p className="text-md font-binance-plex leading-7">
          Step 3: Start the Interaction
        </p>,

        <p className="text-md font-binance-plex leading-7 mt-3">
          Interrogation of{" "}
          <a
            href="https://beta.Odecent Dashboard.ai/telegram"
            target="_blank"
            className="text-colorAccent  font-semiboldbold"
          >
            Telegram ID{" "}
          </a>
          Login with Username & Password
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 1. At right hand side corner Click on{" "}
          <a
            href="https://beta.Odecent Dashboard.ai/telegram"
            target="_blank"
            className="text-colorAccent  font-semiboldbold"
          >
            Telegram
          </a>
        </p>,
        <img src={tele1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2. Open the{" "}
          <a
            href="https://beta.Odecent Dashboard.ai/telegram"
            target="_blank"
            className="text-colorAccent  font-semiboldbold"
          >
            Telegram
          </a>{" "}
          and connect With{" "}
          <span className="text-colorAccent">Odecent Dashboard</span> Bot
        </p>,
        <img src={tele2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3. Click on the Start Button
        </p>,
        <img src={tele3} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "ACTIVE BOTS",
      title: "HOW TO VIEW YOUR ACTIVE BOTS",
      id: "activebot",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Active Bots. Follow
            these steps carefully, and refer to the images provided at each step
            for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1: Access the Dashboard and Find the 'Bot' Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: View Active Bots
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Details of Your Active Bots
            </p>
          </div>

          <hr class="border-2 border-gray-400 my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 1. Go to the{" "}
              <span className="text-colorAccent">Odecent Dashboard</span> and
              locate the "Bot" section.
            </p>
            <img src={report1} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 2. Click on the "ACTIVE BOT" button.
            </p>
            <img src={active2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className=" font-bold text-base leading-7">
              <span className="text-SecondaryColor font-bold text-lg">  Step 3. This will lead you to view your active bots details, which
                include the following information:</span>

              <ul className="list-disc ml-14">
                <li>
                  Date and Time: The date and time when a particular transaction
                  occurred.
                </li>
                <li>Variable Bot ID: A unique identifier for a trading bot.</li>
                <li>
                  API Name: The name of the application programming interface
                  (API) used for communication or data exchange.
                </li>
                <li>
                  Exchange: The name of the trading platform or marketplace
                  where trading activities occur.
                </li>
                <li>
                  Exchange Type: The type of trading exchange, such as
                  cryptocurrency exchange.
                </li>
                <li>
                  Trade Type: The specific type of trade, such as buy, sell, or
                  hold.
                </li>
                <li>
                  Trading Symbol: The symbol or ticker representing a particular
                  financial asset.
                </li>
                <li>
                  Investment Amount: The money invested in a particular trade or
                  transaction.
                </li>
                <li>
                  Telegram Alert Setting: The configuration for receiving alerts
                  (via the Telegram messaging platform).
                </li>
                <li>
                  Status of the Trade: The current status of a trade, which can
                  be open, closed, pending, or other relevant states.
                </li>
              </ul>
            </p>
            <img src={active3} alt="" className="my-4" />
          </div>
        </div>,
      ],
    },

    {
      filterTitle: "TRADES",
      title: "HOW TO MONITOR YOUR TRADES",
      id: "trade",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Trades. Follow these
            steps carefully, and refer to the images provided at each step for
            visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1: Access the Dashboard and Find the 'Trades' Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: View Trades
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Details of Your Active Bots
            </p>
          </div>
          <hr class="border-2 border-gray-400 my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 1. Go to the{" "}
              <span className="text-colorAccent">Odecent Dashboard</span> and
              look for the "Trades" section.
            </p>
            <img src={trades1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 2. Click on “TRADES” button
            </p>
            <img src={trades2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              <span className="text-SecondaryColor font-bold text-lg"
              >
                Step 3. This will lead you to view your active bots details, which
                include the following information:</span>
              <ul className="list-disc ml-14">
                <li>
                  DateTime: The date and time when a particular event or
                  transaction occurred.
                </li>
                <li>Bot ID: A unique identifier for a trading bot.</li>
                <li>
                  API Name: The name of the (API) used for communication or data
                  exchange.
                </li>
                <li>
                  Trade Direction: The direction of the trade, such as "buy" or
                  "sell."
                </li>
                <li>
                  OrderID: A unique identifier for a specific order or trade
                </li>
                <li>
                  Side: The side of the trade, which can be "long" or "short" in
                  the context of trading.
                </li>
                <li>
                  Type: The type of trade or order, e.g., market order, limit
                  order, stop order.
                </li>
                <li>
                  Quantity: The quantity or number of units of an asset involved
                  in the trade.
                </li>
                <li>
                  Price: The price at which the trade was executed or the price
                  of the order.
                </li>
                <li>
                  Order Type: The type of order, e.g., market, limit, stop, or
                  other order types.
                </li>
                <li>
                  Exchange: The name of the trading platform or marketplace
                  where the trade occurred.
                </li>
                <li>
                  Symbol: The symbol or ticker representing a specific financial
                  asset or instrument.
                </li>
                <li>
                  Exchange Type: The type of trading exchange, such as
                  cryptocurrency exchange.
                </li>
                <li>
                  Status: The current status or condition of the trade, such as
                  "open," "closed," or "canceled."
                </li>
              </ul>
            </p>
            <img src={trades3} alt="" className="my-4" />
          </div>
        </div>,
      ],
    },
    {
      filterTitle: "REPORT",
      title: "HOW TO ACCESS YOUR REPORTS",
      id: "report",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Report. Follow these
            steps carefully, and refer to the images provided at each step for
            visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1:Access the Dashboard and Find the Report Section.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: View Reports
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Access Your Trade Report
            </p>
          </div>
          <hr class="border-2 border-gray-400 my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 1. Go to the{" "}
              <span className="text-colorAccent">Odecent Dashboard</span> and
              look for the "Report" section.
            </p>
            <img src={report1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 2. Click on Active Bot button
            </p>
            <img src={report2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              <span className="text-SecondaryColor font-bold text-lg">
                Step 3. You will get to sees your trade report </span>
              <ul className="list-disc ml-4">
                <li>
                  Total: The total number or quantity used in the context of
                  trades.
                </li>
                <li>
                  Winning: The number of trades or positions closed with a
                  profit.
                </li>
                <li>
                  Losing: The number of trades or positions that have been
                  closed with a loss.
                </li>
                <li>
                  Hit rate: The percentage of trades or positions with positive
                  returns.
                </li>
                <li>
                  Invested: The total amount of money invested in various trades
                  or investments.
                </li>
                <li>
                  Balance: The remaining funds or assets after accounting for
                  trades and investments.
                </li>
                <li>
                  Position: The current active trades or open positions in a
                  trading portfolio.
                </li>
                <li>
                  Profit: The amount of profit or gain generated from trading
                  activities.
                </li>
                <li>
                  Brokerage: The amount paid in brokerage charges for executing
                  trades or transactions.
                </li>
                <li>
                  Days: The total days used to track trading or investment
                  duration.
                </li>
              </ul>
            </p>
            <img src={report3} alt="" className="my-4" />
          </div>
        </div>,
      ],
    },

    {
      filterTitle: "P2P",
      title: "How to Complete Your Transfer Fund",
      id: "p2p",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Person to Person. Follow these
            steps carefully, and refer to the images provided at each step for
            visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Transferring funds to a team member’s deposit account is simple and can help support your team's growth. Follow this step-by-step guide to easily transfer funds to a team member’s deposit wallet. Visual aids are included at each step for your convenience.
            </p>

            <br />

            <p className="text-md leading-7 font-binance-plex">
              Step 1: Access the <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "} Page
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: Navigate to the Transfer to User Section.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Search for the Team Member.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Enter the Transfer Amount.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Confirm the Transfer.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 6: Enter Your Transaction Password.
            </p>

          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 font-binance-plex">
              Step 1: Access the <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "} Page
            </p>
            <p className="text-md font-binance-plex leading-7 ">
              Begin by navigating to the Odecent login page.
              <br /> Enter your  <span className="text-SecondaryColor text-lg font-bold capitalize">  username </span> &  <span className="text-SecondaryColor text-lg font-bold capitalize"> password </span> in the designated fields
              and click on the <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent uppercase font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a> button to access your account.

            </p>
            <img src={login1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              2: Navigate to the Transfer to User Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Once logged in, go to User <span className="text-SecondaryColor text-lg font-bold uppercase"> Dashboard ➔ Wallet ➔ Transfer to User </span>. This is where you can initiate a fund transfer to a team member.
            </p>
            <img src={p2p1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              3. Search for the Team Member
            </p>

            <p className="text-md font-binance-plex leading-7 text-justify">
              In the  <span className="text-SecondaryColor text-lg font-bold capitalize"> Transfer to User section </span>, enter the   <span className="text-SecondaryColor text-lg font-bold capitalize">username </span> of the team member to whom you want to  <span className="text-SecondaryColor text-lg font-bold capitalize"> transfer funds</span>. Click the  <span className="text-SecondaryColor text-lg font-bold capitalize">Search</span> button to find a valid user. <br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">  *Note: The user must be a direct team member (user spillover is not considered).</span>
            </p>
            <img src={p2p2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              4: Enter the Transfer Amount
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Once the  <span className="text-SecondaryColor text-lg font-bold capitalize">valid user </span> is found, enter the amount you want to transfer to the selected team member’s deposit wallet. Alternatively, you can click the   <span className="text-SecondaryColor text-lg font-bold capitalize">Max</span> button to transfer the total available balance from your  <span className="text-SecondaryColor text-lg font-bold capitalize"> deposit wallet </span>
            </p>
            <img src={p2p3} alt="" className="my-4" />
            <p>
              Important: Ensure that all information is accurate and matches
              your official documents. Once submitted, these details cannot be
              edited
            </p>

          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              5: Confirm the Transfer
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              After entering the desired amount, click the   <span className="text-SecondaryColor text-lg font-bold capitalize">Transfer</span> button to proceed with the  <span className="text-SecondaryColor text-lg font-bold capitalize"> fund transfer</span>.
            </p>
            <img src={p2p4} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              6: Enter Your Transaction Password
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              For security purposes, you will be required to enter your  <span className="text-SecondaryColor text-lg font-bold capitalize"> transaction password </span> to confirm the transfer. Enter your password in the designated field and click  <span className="text-SecondaryColor text-lg font-bold capitalize">Submit</span> to complete the transaction
            </p>
            <img src={p2p5} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              Success!
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Your funds have been successfully transferred to the team member’s  <span className="text-SecondaryColor text-lg font-bold capitalize">deposit wallet</span>.
            </p>
            <img src={p2p6} alt="" className="my-4" />
          </div>
        </div>,
      ],
    },


    {
      filterTitle: "WITHDRAWAL",
      title: "WITHDRAWAL",
      id: "withdrawal",
      mappingData: [
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to WITHDRAWAL. Follow these
            steps carefully, and refer to the images provided at each step for
            visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Withdrawing your funds from Odecent is a straightforward process. Follow this step-by-step guide to ensure a smooth withdrawal experience. Visual aids are provided for each step to help you navigate the process easily.
            </p>

            <br />

            <p className="text-md leading-7 font-binance-plex">
              Step 1: Access the <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "} Page
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: Navigate to the Withdrawal Section.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Complete Prerequisites.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4:  Check Withdrawal Timing.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Enter the Withdrawal Amount.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 6: Review Withdrawal Details.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 7: Enter Your Transaction Password.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 8: Check Withdrawal Status.
            </p>

          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 font-binance-plex">
              Step 1: Access the <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "} Page
            </p>
            <p className="text-md font-binance-plex leading-7 ">
              Begin by navigating to the Odecent login page.
              <br /> Enter your <span className="text-SecondaryColor text-lg font-bold capitalize"> username</span> & <span className="text-SecondaryColor text-lg font-bold capitalize"> password</span> in the designated fields
              and click on the <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent uppercase font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a> button to access your account.

            </p>
            <img src={login1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              2: Navigate to the Withdrawal Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Once logged in, go to User <span className="text-SecondaryColor text-lg font-bold uppercase"> Dashboard ➔ Wallet ➔  Withdrawal </span>. This section is where you can initiate your <span className="text-SecondaryColor text-lg font-bold capitalize"> withdrawal requests</span>.
            </p>
            <img src={w1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              3. Complete Prerequisites
            </p>

            <p className="text-md font-binance-plex leading-7 text-justify">
              Before you can proceed with a withdrawal, ensure that the following prerequisites are completed:

              <p className="font-bold leading-7 font-binance-plex">
                <a
                  href="/guide#kyc"
                  target="_blank"
                  className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
                >
                  <span className="text-SecondaryColor font-bold">Update KYC</span>
                  <FaExternalLinkAlt />
                </a>{" "}  : Complete your Know Your Customer (KYC) verification.
              </p>


              <p className="font-bold leading-7 font-binance-plex">
                <a
                  href="/guide#walletaddress"
                  target="_blank"
                  className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
                >
                  <span className="text-SecondaryColor font-bold">Update Wallet Address</span>
                  <FaExternalLinkAlt />
                </a>{" "}  :  Make sure your wallet address is up to date.
              </p>

              <p className="font-bold leading-7 font-binance-plex">
                <a
                  href="/guide#transactionpassword"
                  target="_blank"
                  className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
                >
                  <span className="text-SecondaryColor font-bold">Set Transaction Password</span>
                  <FaExternalLinkAlt />
                </a>{" "}  :  Ensure your transaction password is set for security purposes.
              </p>






            </p>
            <img src={w2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              4: Check Withdrawal Timing
            </p>
            <p className="text-md  font-binance-plex leading-7 text-justify">
              Withdrawals are only open during a specific <span className="text-SecondaryColor text-lg font-bold capitalize"> 24-hour </span> period each week:
              <span className="text-SecondaryColor text-lg font-bold capitalize"><br />
                Open: Thursday 11:00 am GMT<br />
                Close: Friday 10:59 am GMT </span>
            </p>

            <p className="text-SecondaryColor font-bold">
              Note: The minimum withdrawal amount is $20. There is no maximum limit on withdrawals.
            </p>

          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              5: Enter the Withdrawal Amount
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Enter the amount you wish to withdraw. Make sure it meets the minimum requirement and then click the  <span className="text-SecondaryColor text-lg font-bold capitalize">Submit</span> button to proceed.
            </p>
            <img src={w4} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              6: Review Withdrawal Details
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              A final <span className="text-SecondaryColor text-lg font-bold capitalize"> confirmation page </span> will appear, showing the following details:
              <br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">Available Funds</span>: The amount available in your withdrawal wallet.<br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">Requested Withdrawal Amount</span>: The amount you have requested to withdraw.<br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">Admin Fees</span>: Any applicable administrative fees.<br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">Final Withdrawal Amount</span>: The net amount that will be sent to your wallet after fees.<br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">User Wallet Address</span>: The address where the funds will be sent.<br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">Review all details carefully to ensure accuracy</span>.<br />
            </p>
            <img src={w5} alt="" className="my-4" />
          </div>


          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              7: Enter Your Transaction Password
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              To confirm the withdrawal, enter your<span className="text-SecondaryColor text-lg font-bold capitalize"> transaction password </span> in the designated field. This step is crucial for ensuring the security of your funds.
            </p>
            <img src={w6} alt="" className="my-4" />
          </div>


          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              8: Check Withdrawal Status
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Once you have submitted your withdrawal request, you can monitor its status through your dashboard.<br /> Navigate to User <span className="text-SecondaryColor font-bold"> Dashboard ➔ Transaction ➔  Withdrawal Transaction </span>Table.<br /> In the last column labeled  <span className="text-SecondaryColor font-bold">Status</span> you will see the current status of your <br />  <span className="text-SecondaryColor font-bold">Transaction:</span>  <span className="text-yellow-600 font-bold">Pending</span>,  <span className="text-red-600 font-bold">Failed</span>, or  <span className="text-green-600 font-bold">Successful</span>.
            </p>
            <img src={w7} alt="" className="my-4" />
          </div>




          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              Success!
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Your <span className="text-SecondaryColor text-lg font-bold uppercase"> withdrawal request </span> has been successfully submitted. Please allow time for processing, and keep an eye on the status updates in your dashboard.
            </p>
            <img src={w8} alt="" className="my-4" />
          </div>
        </div>,
      ],
    },










  ];

  useEffect(() => {
    const dofilte = primaryData.filter((item) => item.id == selectedTitle);

    setMappingData(dofilte);
  }, [selectedTitle]);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace("#", "");
      const matchedTopic = topics.find((topic) => topic.id === hash);
      if (matchedTopic) {
        setSelectedTitle(matchedTopic.id);
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    handleHashChange(); // Call it initially to handle the current hash

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const targetElementStyle = {
    display: "block",
    position: "relative",
    top: "-150px",
    visibility: "hidden",
  };

  const finGuideStyle = {
    display: "none",
    position: "relative",
    top: "-180px",
    visibility: "hidden",
  };

  const showGuide = () => {
    setShowPage(false);
  };

  return (
    <div className=" bg-[#]">
      <div
        className="xl:block w-full hidden h-[510px] bg-no-repeat flex justify-center items-center "
        style={{
          backgroundImage: `url(${guide})`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="lg:pt-32   ">
          <p class="borderText text-center">Document Details </p>
          <p className=" text-center rubik text-lg ">
            Home <span className="font-medium">&#8594;</span>{" "}
            <span className="font-medium">Document Details</span>
          </p>
        </div>
      </div>
      <Drawer
        title="Technical guide"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Guidecarddata
          showFunc={{ showGuide, showPage, onClose, setCheckTech }}
        />
        {/* <Guidecarddata /> */}
      </Drawer>

      <div className=" min-h-screen 2xl:w-[85%] mx-auto h-auto ">
        <div className="lg:h-20 w-full flex items-center justify-between sticky_navbar_guide bg-white z-50">
          <div className="mx-1 text-2xl font-semibold  flex items-center gap-2">
            <div className="w-36 lg:w-48 h-[40px] bg-primaryDark ">
              <div className=" flex justify-center items-center h-full ">
                <img
                  src={logo}
                  alt=""
                  className="px-2  lg:block hidden"
                  onClick={() => navigate("/")}
                />
                <img
                  src={logo}
                  alt="short"
                  className="px-2  lg:hidden block"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>

            <div className="flex justify-center">
              <nav className="lg:flex hidden overflow-x-auto items-center p-1 space-x-1 rtl:space-x-reverse text-sm text-gray-600 bg-gray-500/5 rounded-xl dark:bg-gray-500/20">
                <button
                  role="tab"
                  type="button"
                  className={`${selectedTab === "App"
                      ? "text-SecondaryColor bg-white shadow dark:text-white dark:bg-SecondaryColor"
                      : "hover:text-gray-800 focus:text-SecondaryColor dark:text-gray-400 "
                    } flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none  focus:ring-inset transition-transform duration-300`}
                  onClick={() => setSelectedTab("App")}
                >
                  App
                </button>

                <button
                  role="tab"
                  type="button"
                  className={`${selectedTab === "Trade"
                      ? "text-SecondaryColor bg-white shadow dark:text-white dark:bg-SecondaryColor"
                      : "hover:text-gray-800 focus:text-SecondaryColor dark:text-gray-400 "
                    } flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none   transition-transform duration-300`}
                  onClick={() => setSelectedTab("Trade")}
                >
                  Trade
                </button>
              </nav>
            </div>

            <GoogleTranslate />


          </div>

          <div className="xl:hidden block">
            <div
              className="  rounded-lg px-3 py-2 text-lg  cursor-pointer hover:bg-primaryDark hover:text-white transition-all transition-5s"
              onClick={showDrawer2}
            >
              <BiMenuAltRight size={40} />
            </div>
            <Drawer
              title="Technical guide"
              placement="right"
              onClose={onClose2}
              open={open2}
            >
              <div className="px-2">

<div className="flex justify-between">
              <nav className="lg:hidden flex overflow-x-auto items-center p-1 space-x-1 rtl:space-x-reverse text-sm text-gray-600 bg-gray-500/5 rounded-xl dark:bg-gray-500/20">
                <button
                  role="tab"
                  type="button"
                  className={`${selectedTab === "App"
                      ? "text-SecondaryColor bg-white shadow dark:text-white dark:bg-SecondaryColor"
                      : "hover:text-gray-800 focus:text-SecondaryColor dark:text-gray-400 "
                    } flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none  focus:ring-inset transition-transform duration-300`}
                  onClick={() => setSelectedTab("App")}
                >
                  App
                </button>

                <button
                  role="tab"
                  type="button"
                  className={`${selectedTab === "Trade"
                      ? "text-SecondaryColor bg-white shadow dark:text-white dark:bg-SecondaryColor"
                      : "hover:text-gray-800 focus:text-SecondaryColor dark:text-gray-400 "
                    } flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none   transition-transform duration-300`}
                  onClick={() => setSelectedTab("Trade")}
                >
                  Trade
                </button>
              </nav>

                <div
                  className="bg-SecondaryColor w-[100px] flex justify-center text-white rounded-lg px-3 py-2 text-lg cursor-pointer hover:bg-primaryDark hover:text-white transition-all transition-5s"
                  onClick={() => {
                    window.location.href = "https://app.odecent.com";
                    onClose2();
                  }}
                >
                  Login
                </div>
                </div>


                {/*  */}

                <div className="  rounded-lg px-3 py-2 text-lg  cursor-pointer hover:bg-primaryDark hover:text-white transition-all transition-5s">
                  <ul className="border-r border-[#d5d5d5]">
                    {topics.map((item, index) => {
                      const formattedTopic = item.topic
                        .toLowerCase()
                        .replace(/\b\w/g, (c) => c.toUpperCase());
                      return (
                        <li
                          onClick={() => {
                            setSelected(index);
                            setSelectedTitle(item.id);
                            setShowPage(true);
                            onClose2();
                          }}
                          key={index}
                          className={`${item.id == selectedTitle && showPage
                              ? " bg-SecondaryColor text-white border-colorAccent border-l-4 text-colorAccent"
                              : " bg-white"
                            }
                        text-base font-medium text-black capitalize py-3 rubik text-14 leading-20 lg:pl-6  pl-3 cursor-pointer`}
                        >
                          <a href={`#${item.id}`} className="no-underline ">
                            <span className=" flex items-center gap-2  md:gap-2">
                              <span
                                className={`flex items-center md:gap-2 ${index === selected && showPage
                                    ? ""
                                    : "text-colorAccent"
                                  }`}
                              >
                                {item.icon}
                              </span>
                              <span className="xl:block">{item.topic}</span>
                            </span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/* // */}
              </div>
            </Drawer>
          </div>
          <div className="lg:hidden xl:flex hidden   items-center gap-3 ">
            {/* <StateSelectComponent /> */}
            <div
              className=" underline rounded-lg px-3 py-2 text-lg  cursor-pointer hover:bg-primaryDark hover:text-white transition-all transition-5s"
            // onClick={() => navigate("https://app.odecent.com")}
            >
              <div className="px-8 rounded-lg py-2  bg-SecondaryColor text-white ">
                <Link to={"https://app.odecent.com"}>Login</Link>
              </div>
            </div>
            {/* <div
              className="  xl:hidden md:block hidden  rounded-lg px-3 py-2 text-lg  cursor-pointer hover:bg-primaryDark hover:text-white transition-all transition-5s"
              onClick={showDrawer}
            >
              Technical guide
            </div> */}
            {/* <div
              className="bg-primaryDark text-white rounded-lg px-3 py-2 text-lg  cursor-pointer"
              onClick={() => navigate("/signup")}
            >
              Signup
            </div> */}
          </div>
        </div>

        <div className="grid grid-cols-12 min-h-screen h-auto ">
          <div className="col-span-2 hidden lg:hidden xl:block ">
            <div className="border-2 border-SecondaryColor  py-14 rounded-3xl xl:w-auto md:w-[60px] w-[55px] stickyexample">
              <ul className="border-r border-[#d5d5d5]">
                {topics.map((item, index) => {
                  const formattedTopic = item.topic
                    .toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase());
                  return (
                    <li
                      onClick={() => {
                        setSelected(index);
                        setSelectedTitle(item.id);
                        setShowPage(true);
                      }}
                      key={index}
                      className={`${item.id === selectedTitle && showPage
                          ? "bg-SecondaryColor text-white border-colorAccent border-l-4 text-colorAccent"
                          : "bg-white"
                        }
        text-base font-medium text-black capitalize py-3 rubik text-14 leading-20 lg:pl-6 pl-3 cursor-pointer`}
                    >
                      <a href={`#${item.id}`}>
                        <span className="flex items-center md:gap-2">
                          <span
                            className={`flex items-center md:gap-2 ${index === selected && showPage
                                ? ""
                                : "text-colorAccent"
                              }`}
                          >
                            {item.icon}
                          </span>
                          <span className="xl:block hidden">
                            {item.topic}
                          </span>
                        </span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className=" lg:col-span-12 xl:col-span-10 col-span-12 lg:w-[100%] w-[100%]    h-auto overflow-y-auto">
            <img className="xl:hidden " src={guidemobile} alt="" />
            <div className=" mb-10">
              {showPage && (
                <div className="mx-[20px]">
                  <p
                    className=" text-3xl font-medium hidden"
                    id="finGuide"
                    style={finGuideStyle}
                  >
                    Odecent Beginner's Guide
                  </p>
                  <p className=" text-3xl font-medium" id="finGuide">
                    Odecent Beginner's Guide
                  </p>
                  <p className="mt-1 mb-6 ">
                    Welcome to{" "}
                    <span className="text-colorAccent">Odecent Dashboard</span>!
                    Kickstart your bot journey with quick and easy steps:
                  </p>
                  {mappingData.map((item, index) => {
                    return (
                      <>
                        <div className=" min-h-[300px] h-auto " key={item.id}>
                          <div className=" w-[95%] mx-auto ">
                            <p className="font-medium text-2xl leading-6  mb-4  ">
                              {item.title}
                            </p>
                            {/* {item?.mappingData?.map((itemData) => {
                               return itemData;
                            })} */}
                            {item?.mappingData?.map((itemData, i) => (
                              <React.Fragment key={i}>{itemData}</React.Fragment>
                            ))}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              )}

              {!showPage && (
                <div className=" mb-10">
                  <h1 className=" text-3xl mt-10 font-medium">
                    <span className="text-colorAccent">Odecent Dashboard</span>{" "}
                    Technical guide
                  </h1>
                  {cardData.map((item, index) => {
                    return (
                      <>
                        <div
                          className="font-medium "
                          id={item.jumpTo}
                          style={targetElementStyle}
                        >
                          {item.title}
                        </div>
                        <div
                          className={`px-4  ${checkTech == index
                              ? "  shadow-neumorphicnew py-2 border-2 rounded-3xl"
                              : ""
                            }`}
                        >
                          <div
                            className={`font-medium text-base leading-6 mt-2 ${checkTech == index ? "text-colorAccent " : ""
                              }`}
                            id={item.jumpTo}
                          >
                            {item.title}
                          </div>
                          <p className="text-sm leading-7">
                            {item.description}
                          </p>
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="hidden xl:block col-span-2 ">
            {/* <div className=" bg-colorRight h-auto py-7 rounded-3xl stickyexample">
              <h4 className="text-gray-800 font-rubik font-semibold text-2xl mb-4 rubik px-6">
                Technical guide
              </h4>
              <div>
                <ul className=" ">
                  {cardData.map((item, index) => {
                    console.log("hooooo", item.jumpTo, typeof item.jumpTo);
                    return (
                      <>
                        <li
                          onClick={() => {
                            console.log("Hello world");
                            setShowPage(false);
                            setCheckTech(index);
                          }}
                          className={`text-black rubik font-medium py-2 cursor-pointer px-6 
                            ${
                              checkTech == index && !showPage
                                ? " bg-[#f5f5f5] border-colorAccent border-l-4"
                                : " bg-white"
                            }
                            `}
                          key={index}
                        >
                          <a href={`#${item.jumpTo}`}>{item.title}</a>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div> */}
            <div className="stickyexample">
              {/* <Guidecarddata
                showFunc={{ showGuide, showPage, onClose, setCheckTech }}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guide;








